<template>
  <BaseFinancialStatement docType="BALANCE_SHEET" />
</template>

<script>
import BaseFinancialStatement from '@/component/BaseFinancialStatement.vue'

export default {
  name: 'BalanceSheetList',
  components: { BaseFinancialStatement },
}
</script>
