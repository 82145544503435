<template>
  <div class="content financial-statement-base">
    <div class="form-content">
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <SelectAccountSet
            :name.sync="formData.accountSetName"
            :code="formData.accountSetUnionCode"
            @change="changeAccountSet"
          />
        </a-col>
        <a-col :span="6">
          <a-input :disabled="true" :value="formData.businessUnitName" />
        </a-col>
        <a-col :span="12">
          <AccountingPeriodRange
            :businessUnitCode="formData.businessUnitCode"
            @change="formData.accountingPeriodList = $event"
          />
        </a-col>
      </a-row>
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <CommonQuerySelect
            ref="templateSelectRef"
            api="getFinancialStatementTemplateList"
            :params="{
              businessUnitCode: formData.businessUnitCode,
              type: docType,
            }"
            :code.sync="formData.templateCode"
            :placeholder="$t(`报表模板`)"
          />
        </a-col>
        <a-col :span="6">
          <a-button @click="searchHandle(1)" type="primary">
            {{ $t(`查询`) }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <CommonTable
      v-show="showTable"
      class="no-padding-table"
      :columns.sync="columns"
      :loading="loading"
      :dataSource="tableData"
      :showSetting="false"
      :showPagination="false"
      :showNo="false"
      :tableProps="{
        bordered: true,
      }"
    >
      <template v-slot:content="{ text, record }">
        <div :class="'pl' + (record.level - 1) * 20">{{ text }}</div>
      </template>
      <template v-slot:currentPeriodAmount="{ record }">
        <PriceInput :value.sync="record.currentPeriodAmount" :propsStyle="propsStyle" />
      </template>
      <template v-slot:currentYearAmount="{ record }">
        <PriceInput :value.sync="record.currentYearAmount" :propsStyle="propsStyle" />
      </template>
      <template v-slot:lastPeriodAmount="{ record }">
        <PriceInput :value.sync="record.lastPeriodAmount" :propsStyle="propsStyle" />
      </template>
    </CommonTable>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import SelectAccountSet from '@/component/selectAccountSet'
import AccountingPeriodRange from '@/component/accountingPeriodRange'
import { convertKeysToCamelCase } from '@/common'
import deepClone from '@/common/deepClone'

export default {
  name: 'BaseFinancialStatement',
  components: { SelectAccountSet, AccountingPeriodRange },
  props: {
    docType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        accountSetCode: undefined,
        accountSetName: undefined,
        type: this.docType,
        templateCode: undefined,
        accountingPeriodList: [],
      },
      tableData: [],
      selectedRowKeys: [],
      selectedRows: [],
      loading: false,
      columns: [],
      showTable: false,
      createLoading: false,
    }
  },
  mounted() {
    this.setColumns()
  },
  computed: {
    propsStyle() {
      return {
        border: 'none',
        textAlign: 'right',
      }
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    setColumns() {
      this.columns = [
        {
          title: this.$t('项目名称'),
          dataIndex: 'content',
          scopedSlots: { customRender: 'content' },
        },
        {
          title: this.$t('本期金额'),
          dataIndex: 'currentPeriodAmount',
          scopedSlots: { customRender: 'currentPeriodAmount' },
        },
        {
          title: this.$t('本年金额'),
          dataIndex: 'currentYearAmount',
          scopedSlots: { customRender: 'currentYearAmount' },
        },
        {
          title: this.$t('上期金额'),
          dataIndex: 'lastPeriodAmount',
          scopedSlots: { customRender: 'lastPeriodAmount' },
        },
      ]
    },
    searchHandle() {
      if (!this.formData.businessUnitCode) return
      if (!this.formData.templateCode) return this.$message.warning(this.$t('请选择报表模板'))
      if (this.formData.accountingPeriodList.length === 0)
        return this.$message.warning(this.$t('请选择会计期间'))
      this.getList()
      this.showTable = true
    },
    async getList() {
      this.loading = true
      const data = deepClone(this.formData)
      await http({
        url: api.getFinancialStatement,
        data,
        success: (res) => {
          let result = convertKeysToCamelCase(res.result || [])
          this.tableData = result
        },
      })
      this.loading = false
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    changeAccountSet(value) {
      let arr = value.unionCode?.split('-')
      this.formData.accountSetUnionCode = value.unionCode
      this.formData.businessUnitCode = value.business_unit_code
      this.formData.businessUnitName = value.business_unit_name
      if (arr.length > 1) {
        this.formData.accountSetCode = arr[0]
      } else {
        this.formData.accountSetCode = undefined
      }
      this.$nextTick(() => {
        this.$refs.templateSelectRef.getList()
      })
    },
  },
}
</script>

<style scoped>
.no-padding-table :deep(td),
.no-padding-table :deep(th) {
  padding: 0 !important;
}

.no-padding-table :deep(th) {
  text-align: center;
}
</style>
